import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton, Stack, Typography } from '@esgian/esgianui';
import moment from 'moment-timezone';
import LogDrawer from '@components/Drawers/LogDrawer/LogDrawer';
import CanvasTimeSeriesChart from '@components/Charts/CanvasTimeSeriesChart/CanvasTimeSeriesChart';
import { TextWithTooltipIcon } from '@components';

function PowerDetailsCharts({
  selectedView,
  loading,
  powerPrimaryData,
  powerSecondaryData,
  engineLoadData,
  engineSfocData,
  selectedDate,
  numberOfEngines
}) {
  const [logInfo, setLogInfo] = useState(null);
  const powerMainRef = useRef();
  const powerSecRef = useRef();
  const engLoadRef = useRef();
  const engSfocRef = useRef();
  let primaryMerged = [...powerPrimaryData];

  if (powerSecondaryData[0]) {
    primaryMerged.push({ ...powerSecondaryData[0], hidden: true, unit: '' });
  }
  const handleLogClick = (data) => {
    if (!data) return;
    setLogInfo({ startDate: moment.utc(parseInt(data)), unit: 'hour' });
  };
  const highlightPeriod = useMemo(() => {
    if (!selectedDate) return undefined;
    let start = 24;
    let end = 47;
    if (selectedDate.index === 0) {
      start = 0;
      end = 23;
    }
    if (selectedView === 'min') {
      start = null;
      end = null;
    }
    return {
      startIndex: start,
      endIndex: end
    };
  }, [selectedDate, selectedView]);

  return (
    <>
      <div
        className={'selected-none'}
        style={{
          borderLeft: '1px dashed darkgray',
          position: 'absolute',
          height: selectedView === 'hour' ? '1060px' : '830px',
          visibility: 'hidden',
          // transition: 'all 0.2s ease',
          pointerEvents: 'none',
          zIndex: 1
        }}
        id="hover-line"
      />
      <div className={'custom-tooltip-chart hidden-x-axis-tooltip'}>
        <CanvasTimeSeriesChart
          highlightPeriod={highlightPeriod}
          id={'power-main-chart'}
          handleLogClick={handleLogClick}
          syncRefs={[powerSecRef, engLoadRef, engSfocRef]}
          height={'200px'}
          unit={'kW'}
          series={primaryMerged}
          loading={loading}
          chartRef={powerMainRef}
        />
      </div>
      {!loading && selectedView !== 'min' && (
        <CanvasTimeSeriesChart
          highlightPeriod={highlightPeriod}
          hideCurrentTooltip
          id={'power-sec-chart'}
          maxY={numberOfEngines}
          syncRefs={[powerMainRef, engLoadRef, engSfocRef]}
          height={'200px'}
          unit={''}
          series={powerSecondaryData}
          loading={loading}
          chartRef={powerSecRef}
        />
      )}
      <Stack spacing={2}>
        {loading ? (
          <Skeleton height={'2em'} width={'10em'} />
        ) : (
          <TextWithTooltipIcon
            tooltipText="Engine load graph shows the average engine load per hour for each engine online.
Clicking on one of the bars in the graph on the top of this page will reveal the details per hour for the day before, selected day and the next day.
Selecting View to by minute will provide a more detailed view."
            label={<Typography variant={'body2'}>Detailed engine load in selection</Typography>}
          />
        )}
        <Box className={'custom-tooltip-chart hidden-x-axis-tooltip'}>
          <CanvasTimeSeriesChart
            highlightPeriod={highlightPeriod}
            handleLogClick={handleLogClick}
            id={'power-avg-load-chart'}
            maxY={100}
            syncRefs={[powerMainRef, powerSecRef, engSfocRef]}
            height={'200px'}
            unit={'%'}
            series={engineLoadData}
            loading={loading}
            chartRef={engLoadRef}
          />
        </Box>
      </Stack>
      {loading ? (
        <Skeleton height={'2em'} width={'10em'} />
      ) : (
        <TextWithTooltipIcon
          tooltipText="The graph shows the average SFOC value for each engine while running.
Clicking on one of the bars in the graph on the top of this page will reveal the details per hour for the day before, selected day and the next day.
SFOC is the Specific Fuel Oil Consumption. The SFOC value indicates how much fuel is used to produce 1 kwh. 
This value is calculated from the engines fuel consumption divided by the energy produced.
In general, engine load 80-90% will give the lowest (best) SFOC value."
          label={<Typography variant={'body2'}>Detailed engine SFOC in selection</Typography>}
        />
      )}

      <Box className={'custom-tooltip-chart hidden-x-axis-tooltip'}>
        <CanvasTimeSeriesChart
          highlightPeriod={highlightPeriod}
          handleLogClick={handleLogClick}
          maxY={350}
          minY={150}
          id={'power-avg-sfoc-chart'}
          syncRefs={[powerMainRef, powerSecRef, engLoadRef]}
          height={'200px'}
          unit={'g/kWh'}
          series={engineSfocData}
          loading={loading}
          chartRef={engSfocRef}
        />
      </Box>

      <LogDrawer handleClose={() => setLogInfo(null)} open={!!logInfo} logInfo={logInfo} />
    </>
  );
}

PowerDetailsCharts.propTypes = {
  powerSecondaryData: PropTypes.arrayOf(PropTypes.object),
  powerPrimaryData: PropTypes.arrayOf(PropTypes.object),
  engineLoadData: PropTypes.arrayOf(PropTypes.object),
  engineSfocData: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.string),
  selectedDate: PropTypes.object,
  loading: PropTypes.bool,
  numberOfEngines: PropTypes.number,
  selectedView: PropTypes.string
};

PowerDetailsCharts.defaultProps = {
  numberOfEngines: 5,
  selectedView: 'hour',
  powerSecondaryData: [],
  selectedDate: {},
  powerPrimaryData: [],
  categories: [],
  engineLoadData: [],
  engineSfocData: [],
  loading: false
};

export default PowerDetailsCharts;
