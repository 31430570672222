import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDisplayUnit, useTheme } from '@hooks';
import { Stack, Typography } from '@esgian/esgianui';
import { LegendValue, TextWithTooltipIcon } from '@components';
import { DISPLAY_UNIT, UTC_TIMESTAMP } from '@constants';
import { getUnixTimeStamp, getValue } from '@helpers';
import CanvasTimeSeriesChart from '@components/Charts/CanvasTimeSeriesChart/CanvasTimeSeriesChart';
import moment from 'moment-timezone';
import { highlightPeriodPlugin } from '@components/ChartJsPlugins/HighlightPeriodPlugin';

let tooltipText = (
  <Stack spacing={2}>
    <Typography variant={'caption'}>
      Total regenerated energy from the drilling VFD&apos;s burned or reused.
    </Typography>
    <Typography variant={'caption'}>
      The reused energy is consumed by the other drives on the VFD switchboard and is considered as
      free energy.
    </Typography>
    <Typography variant={'caption'}>
      The burned energy is wasted energy because it is burned over the resistor banks via the brake
      choppers on the switchboard.
    </Typography>
    <Typography variant={'caption'}>
      For context, clicking on one of the bars will reveal the details per hour for the day before,
      selected day and the next day for the Equipment in the Power details graph below.
    </Typography>
  </Stack>
);

function DailyRegeneratedEnergyDrawworks({
  chartRef,
  syncRefs,
  equipmentData,
  setSelectedColumn,
  loading,
  highlightPeriod
}) {
  const { getUnitTitle, convertUnitValue } = useDisplayUnit();
  const {
    theme: { palette }
  } = useTheme();

  const { unit, series } = useMemo(() => {
    let displayUnit = DISPLAY_UNIT.ENERGY;
    if (!equipmentData) return {};
    const { clarifyData } = equipmentData;
    const { DC_Break_Res_Energy, VFD_Reused_Energy } = clarifyData;

    let reused = { default: [], converted: [] };
    let burned = { default: [], converted: [] };
    let max = 0;
    Object.keys(VFD_Reused_Energy).forEach((key) => {
      let value = parseFloat(VFD_Reused_Energy[key]);
      max = max < value ? value : max;
      reused.default.push({ x: getUnixTimeStamp(key), y: getValue(value) });
      reused.converted.push({
        x: getUnixTimeStamp(key),
        y: convertUnitValue(value, displayUnit, false)
      });
    });

    Object.keys(DC_Break_Res_Energy).forEach((key) => {
      let value = parseFloat(DC_Break_Res_Energy[key]);
      max = max < value ? value : max;
      burned.default.push({ x: getUnixTimeStamp(key), y: getValue(value) });
      burned.converted.push({
        x: getUnixTimeStamp(key),
        y: convertUnitValue(value, displayUnit, false)
      });
    });

    const { title, converted } = getUnitTitle(max, displayUnit);
    return {
      series: [
        {
          type: 'bar',
          stack: 1,
          borderSkipped: true,
          backgroundColor: palette.success.main,
          label: 'Reused',
          data: converted ? reused.converted : reused.default
        },
        {
          type: 'bar',
          stack: 1,
          borderSkipped: true,
          backgroundColor: palette.error.main,
          label: 'Burned',
          legendTooltip: tooltipText,
          data: converted ? burned.converted : burned.default
        }
      ],
      unit: title
    };
  }, [equipmentData]);

  const handleColumnClick = useCallback(
    (val) => {
      const { index } = val;
      const timestamp = series[0]?.data[index].x;

      setSelectedColumn({
        date: moment.utc(parseInt(timestamp)).format(UTC_TIMESTAMP),
        index: index,
        isLast: index === series[0].data?.length - 1
      });
    },
    [series]
  );

  return (
    <Stack spacing={2}>
      <Typography variant={'subtitle1'}>Daily drilling VFD regenerated energy</Typography>
      <CanvasTimeSeriesChart
        highlightPeriod={highlightPeriod}
        syncRefs={syncRefs}
        height={'170px'}
        disableHoverLinePlugin
        enableBarHover
        handlePointClick={handleColumnClick}
        stackedBar
        series={series}
        unit={unit}
        loading={loading}
        chartRef={chartRef}
        includeTooltipSum={true}
        id={'daily-regenerated-energy-chart'}
      />
    </Stack>
  );
}

DailyRegeneratedEnergyDrawworks.propTypes = {
  equipmentData: PropTypes.object,
  setSelectedColumn: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  syncRefs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
  ).isRequired,
  chartRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
    .isRequired,
  highlightPeriod: PropTypes.shape({
    startIndex: PropTypes.number,
    endIndex: PropTypes.number
  }),
  categories: PropTypes.arrayOf(PropTypes.string)
};

DailyRegeneratedEnergyDrawworks.defaultProps = {
  categories: [],
  equipmentData: null,
  highlightPeriod: {},
  loading: false
};

export default DailyRegeneratedEnergyDrawworks;
