import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@esgian/esgianui';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import moment from 'moment-timezone';
import CanvasTimeSeriesChart from '@components/Charts/CanvasTimeSeriesChart/CanvasTimeSeriesChart';
import { DATE_FORMAT, DISPLAY_UNIT, UTC_TIMESTAMP } from '@constants';
import ViewBySelect from '@components/Inputs/ViewBySelect/ViewBySelect';
import { TextWithTooltipIcon } from '@components';

function PeriodicOverviewWithDetailsChart({
  selectedView,
  handleViewChange,
  primarySeries,
  loading,
  handleLogClick,
  primaryUnit,
  detailsTitle,
  detailsTitleTooltip,
  selectedColumn,
  setSelectedColumn,
  secondarySeries,
  SecondaryPrimaryChart,
  secondaryPrimaryProps,
  secondaryUnit,
  enableBarHover,
  secondaryStacked,
  disableHoverLinePlugin,
  title,
  titleTooltipText,
  subTitle,
  id
}) {
  const mainChartRef = useRef();
  const mainSecChartRef = useRef();
  const secChartRef = useRef();
  const displayUnit = useSelector(getDisplayUnit);

  const logClick = useCallback((date, unit) => {
    handleLogClick({
      startDate: moment.utc(parseInt(date)).format(UTC_TIMESTAMP),
      unit: unit
    });
  }, []);

  const handleColumnClick = useCallback(
    (val) => {
      const { index } = val;
      const timestamp = primarySeries[0]?.data[index].x;

      setSelectedColumn({
        date: moment.utc(parseInt(timestamp)).format(UTC_TIMESTAMP),
        index: index,
        isLast: index === primarySeries[0].data?.length - 1
      });
    },
    [primarySeries]
  );

  const getSubTitle = useMemo(() => {
    switch (displayUnit) {
      case DISPLAY_UNIT.CO2:
        return 'Daily equipment CO2e emissions';
      case DISPLAY_UNIT.NOX:
        return 'Daily equipment NOx emissions';
      case DISPLAY_UNIT.FUEL:
        return 'Daily equipment fuel consumption';
      case DISPLAY_UNIT.ENERGY:
        return 'Daily equipment energy consumption';
    }
  }, [displayUnit]);

  const highlightPeriod = useMemo(() => {
    let start = 24;
    let end = 47;
    if (selectedColumn?.index === 0) {
      start = 0;
      end = 23;
    }
    if (selectedView === 'min') {
      start = null;
      end = null;
    }
    return {
      startIndex: start,
      endIndex: end
    };
  }, [selectedColumn, selectedView]);

  const mainHighlightPeriod = useMemo(() => {
    if (!selectedColumn)
      return {
        startIndex: 0,
        endIndex: 0
      };

    return {
      startIndex: selectedColumn.index,
      endIndex: selectedColumn.index
    };
  }, [selectedColumn]);

  const fullDetailsTitle = useMemo(() => {
    if (!selectedColumn) return detailsTitle;
    let start = moment.utc(selectedColumn.date);
    if (selectedColumn.index > 0) {
      start.subtract(1, 'day');
    }
    let end = moment.utc(selectedColumn.date);
    if (!selectedColumn.isLast) {
      end = end.add(1, 'day');
    }

    return `${detailsTitle} - ${start.format(DATE_FORMAT)} ${
      selectedColumn.date ? `to ${end.format(DATE_FORMAT)}` : ''
    }`;
  }, [selectedColumn]);

  return (
    <Stack spacing={2}>
      <Box>
        <TextWithTooltipIcon
          tooltipText={titleTooltipText}
          label={<Typography variant={'h6'}>{title}</Typography>}
        />
        <Typography variant="body2">{getSubTitle}</Typography>
      </Box>
      <CanvasTimeSeriesChart
        highlightPeriod={mainHighlightPeriod}
        syncRefs={[mainSecChartRef]}
        handlePointClick={handleColumnClick}
        enableBarHover
        disableHoverLinePlugin
        height={'310px'}
        hideEmptyTooltipValues
        unit={primaryUnit}
        loading={loading}
        series={primarySeries}
        stackedBar
        chartRef={mainChartRef}
        handleLogClick={(date) => logClick(date, 'day')}
        includeTooltipSum={true}
        id={'daily-equipment-consumption-chart'}
      />
      {SecondaryPrimaryChart && (
        <SecondaryPrimaryChart
          chartRef={mainSecChartRef}
          {...{ ...secondaryPrimaryProps, highlightPeriod: mainHighlightPeriod }}
          syncRefs={[mainChartRef]}
        />
      )}
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Box>
          <TextWithTooltipIcon
            tooltipText={detailsTitleTooltip}
            label={
              <Typography sx={{ letterSpacing: '0.15px' }} variant={'subtitle1'}>
                {fullDetailsTitle}
              </Typography>
            }
          />
          {typeof subTitle === 'string' ? (
            <Typography variant="body2">{subTitle}</Typography>
          ) : (
            subTitle
          )}
        </Box>
        {handleViewChange && (
          <ViewBySelect
            selectedView={selectedView}
            handleChange={handleViewChange}
            disabled={!handleViewChange}
          />
        )}
      </Stack>
      <CanvasTimeSeriesChart
        highlightPeriod={highlightPeriod}
        enableBarHover={enableBarHover}
        disableHoverLinePlugin={disableHoverLinePlugin}
        height={'310px'}
        hideEmptyTooltipValues
        unit={secondaryUnit}
        loading={loading}
        series={secondarySeries}
        stackedBar={secondaryStacked}
        chartRef={secChartRef}
        handleLogClick={(date) => logClick(date, 'hour')}
        includeTooltipSum={true}
        id={id}
      />
    </Stack>
  );
}

PeriodicOverviewWithDetailsChart.propTypes = {
  handleLogClick: PropTypes.func.isRequired,
  secondaryUnit: PropTypes.string,
  enableBarHover: PropTypes.bool,
  secondaryStacked: PropTypes.bool,
  disableHoverLinePlugin: PropTypes.bool,
  primaryUnit: PropTypes.string,
  primarySeries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number
        })
      ).isRequired
    })
  ),
  secondarySeries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number
        })
      ).isRequired
    })
  ),
  loading: PropTypes.bool,
  selectedView: PropTypes.oneOf(['hour', 'min']),
  handleViewChange: PropTypes.func,
  SecondaryPrimaryChart: PropTypes.elementType,
  secondaryPrimaryProps: PropTypes.object,
  detailsTitle: PropTypes.string,
  detailsTitleTooltip: PropTypes.string,
  setSelectedColumn: PropTypes.func.isRequired,
  title: PropTypes.string,
  titleTooltipText: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  selectedColumn: PropTypes.shape({
    date: PropTypes.string,
    index: PropTypes.number,
    isLast: PropTypes.bool
  }),
  id: PropTypes.string
};

PeriodicOverviewWithDetailsChart.defaultProps = {
  primarySeries: [],
  secondarySeries: [],
  loading: false,
  enableBarHover: false,
  secondaryStacked: false,
  disableHoverLinePlugin: false,
  handleViewChange: undefined,
  SecondaryPrimaryChart: undefined,
  secondaryPrimaryProps: undefined,
  detailsTitle: 'Details',
  selectedView: 'hour',
  primaryUnit: '',
  secondaryUnit: '',
  selectedColumn: 0,
  id: ''
};

export default PeriodicOverviewWithDetailsChart;
