import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, ChevronRightIcon, IconButton } from '@esgian/esgianui';
import DateRangeBarChart from './DateRangeBarChart';
import DateRangeWellSelector from './DateRangeWellSelector';
import DateRangeSliderControl from './DateRangeSliderControl';
import DateRangeDropdown from '@components/DateRangeDropdown';
import { useDispatch } from 'react-redux';
import { setMainPagePeriod } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { DATE_FORMAT } from '@constants';
import { useQuery } from '@tanstack/react-query';
import { fetchRigWells } from '@api/DdrReport';
import moment from 'moment';

const DateRangeControlPanel = ({
  start,
  end,
  onSave,
  selectedDateName,
  selectedDate,
  selectedRig
}) => {
  const componentRef = useRef(null);
  const dispatch = useDispatch();
  const [currentSelectedDates, setCurrentSelectedDates] = useState(selectedDate);
  const fullStartDate = moment(start).startOf('day');
  const fullEndDate = moment(end).endOf('day');
  const isMoreThanOneYear = fullEndDate.diff(fullStartDate, 'months') > 12;

  const initialDisplayedStart = isMoreThanOneYear
    ? fullEndDate.clone().subtract(12, 'months')
    : fullStartDate;
  const initialDisplayedEnd = fullEndDate;

  const [currentDisplayedDates, setCurrentDisplayedDates] = useState([
    moment(initialDisplayedStart),
    moment(initialDisplayedEnd)
  ]);

  useEffect(() => {
    const handler = setTimeout(() => {
      const [newStartDate, newEndDate] = currentSelectedDates;
      const [prevStartDate, prevEndDate] = selectedDate;
      const isStartDateChanged = !moment(newStartDate).isSame(moment(prevStartDate), 'day');
      const isEndDateChanged = !moment(newEndDate).isSame(moment(prevEndDate), 'day');
      if (isStartDateChanged || isEndDateChanged) {
        onSave({
          startDate: newStartDate,
          endDate: newEndDate
        });
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [currentSelectedDates]);

  useEffect(() => {
    setCurrentSelectedDates(selectedDate);
  }, [selectedDate]);

  const handleMoveTimeline = (direction) => {
    const [currentStart, currentEnd] = currentDisplayedDates;

    const newStart =
      direction === 'left' ? currentStart.subtract(1, 'months') : currentStart.add(1, 'months');

    let newEnd =
      direction === 'left' ? currentEnd.subtract(1, 'months') : currentEnd.add(1, 'months');

    if (newStart.isBefore(fullStartDate)) {
      setCurrentDisplayedDates([moment(fullStartDate), moment(currentDisplayedDates[1])]);
    } else if (newEnd.isAfter(fullEndDate)) {
      setCurrentDisplayedDates([moment(currentDisplayedDates[0]), moment(fullEndDate)]);
    } else {
      setCurrentDisplayedDates([moment(newStart), moment(newEnd)]);
    }
  };

  const wellsLookup = useQuery({
    queryKey: ['lookupWells', { selectedRig: selectedRig }],
    enabled: !!selectedRig,
    placeholderData: [],
    queryFn: ({ queryKey, signal }) => {
      const endDate = moment().format(DATE_FORMAT);
      return fetchRigWells(signal, { ...queryKey[1], endDate })
        .then((result) => {
          return result
            .filter(({ starttime, endtime }) => {
              return (
                moment(endtime).diff(moment(starttime), 'days') >= 3 &&
                moment(starttime).isSameOrAfter(fullStartDate) &&
                moment(endtime).isSameOrBefore(fullEndDate)
              );
            })
            .sort((a, b) => {
              return (
                moment.parseZone(b.starttime).valueOf() - moment.parseZone(a.starttime).valueOf()
              );
            });
        })
        .catch(() => []);
    }
  });

  useEffect(() => {
    const preventZoom = (e) => {
      if (e.ctrlKey || e.metaKey || e.deltaY) {
        e.preventDefault();
      }
    };

    const element = componentRef.current;
    element.addEventListener('wheel', preventZoom, { passive: false });

    return () => {
      element.removeEventListener('wheel', preventZoom);
    };
  }, []);

  return (
    <Box sx={{ width: '100%', position: 'relative' }} id={'date-range-section'}>
      <DateRangeDropdown
        startDate={selectedDate[0]}
        endDate={selectedDate[1]}
        selectedDateName={selectedDateName || ''}
        onSave={(period) => dispatch(setMainPagePeriod(period))}
        inputVariant="noBorder"
        showLabel={false}
        maxWidth={310}
        fullWidth={true}
        allowTimeZoneChange
        id={'date-range-dropdown'}
      />
      <Box sx={{ display: 'flex', mt: '8px' }}>
        {isMoreThanOneYear ? (
          <IconButton
            size={'small'}
            onClick={() => handleMoveTimeline('left')}
            sx={{ padding: 0, marginRight: 1, rotate: '180deg', height: 56 }}
            disabled={moment(currentDisplayedDates[0]).isSameOrBefore(fullStartDate)}>
            <ChevronRightIcon fontSize="small" color={'inherit'} />
          </IconButton>
        ) : null}
        <Box
          ref={componentRef}
          sx={{
            width: '100%',
            height: '72px',
            position: 'relative',
            touchAction: 'none',
            userSelect: 'none'
          }}
          id={'date-range-slider'}>
          <DateRangeWellSelector
            start={moment(currentDisplayedDates[0]).format('YYYY-MM-DD')}
            end={moment(currentDisplayedDates[1]).format('YYYY-MM-DD')}
            selectedDates={currentSelectedDates}
            onSave={onSave}
            wellsLookup={wellsLookup}
          />
          <DateRangeBarChart
            start={moment(currentDisplayedDates[0]).format('YYYY-MM-DD')}
            end={moment(currentDisplayedDates[1]).format('YYYY-MM-DD')}
            selectedDates={currentSelectedDates}
            earliestStart={start}
            latestEnd={end}
            setCurrentDisplayedDates={setCurrentDisplayedDates}
            selectedRig={selectedRig}
          />
          <DateRangeSliderControl
            start={moment(currentDisplayedDates[0]).format('YYYY-MM-DD')}
            end={moment(currentDisplayedDates[1]).format('YYYY-MM-DD')}
            selectedDates={currentSelectedDates}
            setSelectedDates={setCurrentSelectedDates}
          />
        </Box>
        {isMoreThanOneYear ? (
          <IconButton
            size={'small'}
            onClick={() => handleMoveTimeline('right')}
            sx={{ padding: 0, marginLeft: 1, height: 56 }}
            disabled={moment(currentDisplayedDates[1]).isSameOrAfter(fullEndDate)}>
            <ChevronRightIcon fontSize="small" color={'inherit'} />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  );
};

DateRangeControlPanel.propTypes = {
  selectedDateName: PropTypes.string,
  selectedDate: PropTypes.array.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  selectedRig: PropTypes.object.isRequired
};

DateRangeDropdown.defaultProps = {
  end: moment().format(DATE_FORMAT)
};

export default DateRangeControlPanel;
