import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { Box } from '@esgian/esgianui';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import Wave from 'react-wavify';
import moment, { Moment } from 'moment-timezone';
import { fetchOverviewKeyFiguresData } from '../../api/Rig';
import { getDisplayUnit } from '../../store/features';
import { API_ROLLUP, DATE_FORMAT } from '../../constants';

interface BarChartProps {
  start: Moment;
  end: Moment;
  earliestStart: Moment;
  latestEnd: Moment;
  selectedDates: [string, string];
  setCurrentDisplayedDates: (dates: [Moment, Moment]) => void;
  selectedRig: string;
}

const fillMissingDates = (
  startDate: Moment,
  endDate: Moment,
  dataObject: any
): Record<string, string> => {
  const filledData: Record<string, string> = { ...dataObject };
  let currentDate = moment(startDate);
  while (currentDate.isSameOrBefore(moment(endDate), 'day')) {
    const dateKey = currentDate.format('YYYY-MM-DDT12:00:00');
    if (!(dateKey in filledData)) {
      filledData[dateKey] = '0';
    }

    currentDate = currentDate.add(1, 'day');
  }
  return filledData;
};

const BarChart: React.FC<BarChartProps> = ({
  start,
  end,
  earliestStart,
  latestEnd,
  selectedDates,
  setCurrentDisplayedDates,
  selectedRig
}) => {
  const displayUnit = useSelector(getDisplayUnit);
  const [overviewData, setOverviewData] = useState<Record<string, any> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const startDate = moment(start.clone().format('YYYY-MM-DD')).startOf('D');
  const endDate = moment(end.format('YYYY-MM-DD')).endOf('D');

  const earliestStartTime = moment(earliestStart.clone().format('YYYY-MM-DD')).startOf('D');
  const latestEndTime = moment(latestEnd.clone().format('YYYY-MM-DD')).endOf('D');

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    const loadOverviewData = async () => {
      try {
        const data = await fetchOverviewKeyFiguresData(
          abortController.signal,
          { selectedRig, startDate: earliestStart, endDate: latestEnd },
          API_ROLLUP.DAY
        );
        setOverviewData(data);
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.error(error);
        }
      } finally {
        setLoading(false);
      }
    };

    loadOverviewData();

    return () => {
      abortController.abort();
    };
  }, [selectedRig, earliestStart, latestEnd]);

  const currentOverviewData = useMemo(() => {
    const data = overviewData?.clarifyData?.[`Rig${displayUnit}`];
    if (!data) {
      return [];
    }
    const filteredData = Object.keys(data)
      .filter((date) => {
        const currentDate = moment(date.split('T')[0]).startOf('D');
        return currentDate.isBetween(earliestStartTime, latestEndTime.endOf('day'), null, '[]');
      })
      .reduce<Record<string, string>>((result, date) => {
        result[date] = data[date];
        return result;
      }, {});

    return filteredData;
  }, [overviewData, earliestStartTime, latestEndTime, displayUnit]);

  const displayUnitData = useMemo(
    () => fillMissingDates(earliestStartTime, latestEndTime, currentOverviewData),
    [currentOverviewData]
  );

  const labels = useMemo(() => {
    if (displayUnitData) {
      return Object.keys(displayUnitData).map((d) => moment(d).format(DATE_FORMAT));
    }
    return [];
  }, [displayUnitData]);

  const values = useMemo(() => {
    if (displayUnitData) {
      return Object.values(displayUnitData).map((i) => parseInt(i, 10));
    }
    return [];
  }, [displayUnitData]);

  const backgroundColors = useMemo(() => {
    const startDate = moment(selectedDates[0].split('T')[0]).startOf('day');
    const endDate = moment(selectedDates[1].split('T')[0]).endOf('day');

    return labels.map((label) => {
      const currentDate = moment(label);
      return currentDate.isBetween(startDate, endDate, null, '[]') ? '#0E9EFF' : '#7B7B7B';
    });
  }, [labels, selectedDates]);

  const data = useMemo(() => {
    return {
      labels,
      datasets: [
        {
          label: 'Dataset 1',
          data: values,
          backgroundColor: backgroundColors,
          borderColor: 'transparent',
          borderWidth: 0
        }
      ]
    };
  }, [labels, values, backgroundColors]);

  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedSetCurrentDisplayedDates = useCallback(debounce(setCurrentDisplayedDates, 20), []);

  const config = useMemo(() => {
    return {
      type: 'bar',
      data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          tooltip: { enabled: false },
          zoom: {
            zoom: {
              drag: {
                enabled: false
              },
              pinch: {
                enabled: true
              },
              wheel: {
                enabled: true,
                modifierKey: 'ctrl'
              },
              mode: 'x',
              onZoom: ({ chart }: { chart: any }) => {
                const xAxis = chart.scales.x;
                setCurrentDisplayedDates([
                  moment(earliestStartTime).add(xAxis.min, 'day').startOf('day'),
                  moment(earliestStartTime).add(xAxis.max, 'day').endOf('day')
                ]);
              }
            },
            pan: {
              enabled: true,
              speed: 1,
              mode: 'x',
              onPan: ({ chart }: { chart: any }) => {
                const xAxis = chart.scales.x;
                debouncedSetCurrentDisplayedDates([
                  moment(earliestStartTime).add(xAxis.min, 'day').startOf('day'),
                  moment(earliestStartTime).add(xAxis.max, 'day').endOf('day')
                ]);
              }
            },
            limits: {
              x: {
                minRange: 180
              }
            }
          }
        },
        scales: {
          x: {
            min: moment(startDate).diff(moment(earliestStartTime), 'days'),
            max: moment(endDate).diff(moment(earliestStartTime), 'days') + 1,
            offset: false,
            grid: { display: false },
            ticks: { display: false }
          },
          y: {
            display: false
          }
        },
        layout: {
          padding: 0
        },
        datasets: {
          bar: {
            barThickness: 'flex',
            maxBarThickness: 6
          }
        }
      }
    };
  }, [endDate, startDate]);

  return (
    <Box sx={{ width: '100%', position: 'absolute', top: 20 }}>
      {loading ? (
        <Wave
          fill="#7B7B7B"
          paused={false}
          style={{ height: '18px' }}
          options={{
            height: 5,
            amplitude: 5,
            speed: 0.4,
            points: 5
          }}
        />
      ) : (
        <Bar height={20} data={config.data} options={config.options as any} />
      )}
    </Box>
  );
};

export default BarChart;
