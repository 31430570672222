import React, { useMemo } from 'react';
import { Slider } from '@esgian/esgianui';
import moment, { Moment } from 'moment-timezone';
import { DATE_FORMAT, DATE_TIMEZONE_FORMAT } from '../../constants';

interface TimeRangeSliderProps {
  start: Moment;
  end: Moment;
  selectedRange: [string, string];
  selectedTimeZone: string;
  onRangeChange: (range: [string, string]) => void;
}

const TimeRangeSlider: React.FC<TimeRangeSliderProps> = ({
  start,
  end,
  selectedRange,
  selectedTimeZone,
  onRangeChange
}) => {
  const startDate = moment(start.clone().format('YYYY-MM-DD')).startOf('D');
  const endDate = moment(end.format('YYYY-MM-DD')).endOf('D');
  const totalDays = endDate.diff(startDate, 'days');
  const selectedStart = moment.tz(selectedRange[0], selectedTimeZone);
  const selectedEnd = moment.tz(selectedRange[1], selectedTimeZone);

  const range = [selectedStart.diff(startDate, 'days'), selectedEnd.diff(startDate, 'days')];

  const handleChange = (_: Event, newValue: number | number[]) => {
    const [startValue, endValue] = newValue as number[];
    const newStart = startDate.clone().add(startValue, 'days').startOf('day');
    const newEnd = startDate.clone().add(endValue, 'days').endOf('day');

    onRangeChange([newStart.format(DATE_TIMEZONE_FORMAT), newEnd.format(DATE_TIMEZONE_FORMAT)]);
  };

  const formatDay = (days: number) => {
    return startDate.clone().add(days, 'days').format(DATE_FORMAT);
  };

  const marker = useMemo(() => {
    const marks = [];
    const today = moment().tz(selectedTimeZone).startOf('day');
    const diffInMonths = endDate.diff(startDate, 'months', true);
    if (diffInMonths <= 2) {
      const current = startDate.clone().startOf('week');
      while (current.isBefore(endDate)) {
        marks.push({
          value: current.diff(startDate, 'days'),
          label: current.format(DATE_FORMAT)
        });
        current.add(1, 'week');
      }
    } else {
      const current = startDate.clone().startOf('month');
      while (current.isBefore(endDate)) {
        marks.push({
          value: current.diff(startDate, 'days'),
          label: current.format('YYYY-MM')
        });
        current.add(1, 'month');
      }

      if (today.isBetween(startDate, endDate, undefined, '[]')) {
        const todayValue = today.diff(startDate, 'days');
        marks.push({
          value: todayValue,
          label: 'Today'
        });

        const lastValue = endDate.diff(startDate, 'days');
        if (lastValue - todayValue <= 25) {
          return marks.filter((mark) => mark.label !== endDate.format('YYYY-MM'));
        }
      }
    }

    return Array.from(new Map(marks.map((mark) => [mark.value, mark])).values()).sort(
      (a, b) => a.value - b.value
    );
  }, [start, end]);

  return (
    <Slider
      value={range}
      onChange={handleChange}
      min={0}
      max={totalDays}
      step={1}
      valueLabelDisplay="auto"
      valueLabelFormat={(value: any) => formatDay(value)}
      marks={marker}
      sx={{
        position: 'absolute',
        bottom: 27,
        zIndex: 2,
        padding: '0',
        m: '0',
        width: '100% !important',
        '& .MuiSlider-markLabel': {
          transform: 'rotate(0deg)',
          whiteSpace: 'nowrap',
          marginLeft: '-20px',
          top: '10px',
          fontSize: 10,
          color: ({ palette }: any) => palette.text.disabled
        },
        '& .MuiSlider-markLabel[data-index="0"]': {
          marginLeft: '1px !important'
        },
        '& .MuiSlider-valueLabelOpen': {
          borderRadius: '4px',
          width: '76px',
          height: '24px',
          rotate: '-45deg',
          transform: 'translate(-6px, 6px)!important'
        },
        '& .MuiSlider-valueLabelCircle': {
          rotate: '-45deg'
        },
        '& .MuiSlider-track': {
          color: '#88C7FF'
        },
        '& .MuiSlider-thumb': {
          color: '#0E9EFF',
          border: 'none'
        },
        '& .MuiSlider-rail': {
          color: '#555555'
        }
      }}
    />
  );
};

export default TimeRangeSlider;
