import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SimpleBarChart from '../SimpleBarChart';
import { useTheme } from '@hooks';
import PercentageLoadPerEngineTooltip from '@components/Charts/PercentageLoadPerEngineChart/PercentageLoadPerEngineTooltip';

const getSecondAccessDef = (color) => {
  return {
    tickAmount: 5,
    opposite: true,
    forceNiceScale: true,
    title: {
      text: 'g/kWh',
      offsetY: -150,
      offsetX: -20,
      rotate: 0,
      style: {
        color: color,
        fontFamily: ['Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
        fontSize: '10px',
        fontWeight: 100
      }
    },
    decimalsInFloat: 0,
    labels: {
      offsetX: -8,
      offsetY: 0,
      style: {
        colors: [color],
        fontFamily: ['Roboto', 'helvetica', 'Arial', 'sans-serif'].join(',')
      }
    }
  };
};

const getValue = (val) => {
  if (val === null || val === 'null') {
    return 0;
  }

  return parseFloat(val);
};

function PercentageLoadPerEngineChart({ loading, powerMgmtData }) {
  const { theme } = useTheme();

  const { series, categories } = useMemo(() => {
    if (!powerMgmtData) return [];
    const { clarifyData, numOfEngines } = powerMgmtData;

    let load = [];
    let cat = [];
    let sfoc = [];
    for (let i = 1; i < numOfEngines + 1; i++) {
      cat.push(`Engine ${i}`);
      let loadData = Object.values(clarifyData[`Eng${i}Lavg`])?.filter((val) => val !== null);
      let sfocData = Object.values(clarifyData[`Eng${i}SFOC`])?.filter((val) => val !== null);
      let avgLoad = loadData.reduce((a, b) => a + getValue(b), 0) / loadData.length;
      load.push(avgLoad ? parseInt(avgLoad) : 0);
      let avgSfoc = sfocData.reduce((a, b) => a + getValue(b), 0) / sfocData.length;
      sfoc.push(avgSfoc ? parseInt(avgSfoc) : 0);
    }

    return {
      categories: cat,
      series: [
        { name: 'Load', type: 'column', data: load },
        { name: 'SFOC', type: 'column', data: sfoc }
      ]
    };
  }, [powerMgmtData]);
  const secondaryAxis = useMemo(() => {
    const {
      palette: {
        charts: { twoColorsContrast }
      }
    } = theme;

    return getSecondAccessDef(twoColorsContrast[1]);
  }, [theme]);
  const {
    palette: {
      charts: { twoColorsContrast }
    }
  } = theme;
  return (
    <SimpleBarChart
      colors={twoColorsContrast}
      loading={loading}
      tooltipUnit={'%'}
      secondaryAxis={secondaryAxis}
      primaryMaxValue={100}
      primaryXAxisLabel={'%'}
      primaryTitleOffsetY={25}
      height={385}
      categories={categories}
      legendOffsetY={0}
      series={series}
      customTooltipContent={(series, dataPointIndex) => (
        <PercentageLoadPerEngineTooltip
          theme={theme}
          categories={categories}
          series={series}
          dataPointIndex={dataPointIndex}
        />
      )}
      id={'avg-engine-load-chart'}
    />
  );
}

PercentageLoadPerEngineChart.propTypes = {
  powerMgmtData: PropTypes.object,
  loading: PropTypes.bool
};

PercentageLoadPerEngineChart.defaultProps = {
  powerMgmtData: null,
  loading: false
};

export default PercentageLoadPerEngineChart;
