import React, { useMemo, useState } from 'react';
import { Stack, Typography } from '@esgian/esgianui';
import { LegendValue, TextWithTooltipIcon } from '@components/Display';
import { adjustColorOpacity } from '@components/Charts/ChartJsColorHelpers';
import PropTypes from 'prop-types';

function CanvasLegends({ chartRef = undefined, id = '', data = { datasets: [] } }) {
  const [legendActive, setLegendActive] = useState([]);

  const handleLegendClick = (index) => {
    const chart = chartRef.current;
    if (chartRef.current) {
      const meta = chart.getDatasetMeta(index);
      let value = !chart.data.datasets[index].hidden;
      meta.hidden = meta.hidden === null ? value : null;
      if (meta.hidden) {
        setLegendActive([...legendActive, index]);
      } else {
        setLegendActive(() => [...legendActive.filter((v) => v !== index)]);
      }
      chart.update();
    }
  };
  const handleLegendHover = (itemIndex, highlight) => {
    console.log(itemIndex, highlight);
    const chart = chartRef.current;
    if (chartRef.current) {
      chart.options.animation.duration = 200; // Set a faster animation duration
      chart.data.datasets.forEach((dataset, index) => {
        if (highlight && index !== itemIndex) {
          dataset.borderColor = adjustColorOpacity(dataset.borderColor, 0.1);
          dataset.backgroundColor = adjustColorOpacity(dataset.backgroundColor, 0.1);
        } else {
          dataset.borderColor = adjustColorOpacity(dataset.borderColor, 1);
          dataset.backgroundColor = dataset.originalBackgroundColor;
        }
      });
      chart.update();
    }
  };

  const renderLegend = (dataset, groupName = null, key) => {
    return (
      <Stack
        direction={'row'}
        spacing={1}
        key={key}
        sx={{ rowGap: 'normal' }}
        useFlexGap
        flexWrap="wrap">
        {groupName && <Typography variant={'caption'}>{groupName}:</Typography>}
        {[...dataset]
          .filter(({ hidden }) => !hidden)
          .map(
            (
              {
                type,
                label,
                legendTooltip,
                backgroundColor,
                borderColor,
                disableLegend,
                subHeader,
                group,
                order,
                subHeaderTooltip
              },
              i
            ) => {
              let index = i;
              if (group) {
                index = order;
              }
              if (subHeader) {
                if (subHeaderTooltip) {
                  return (
                    <TextWithTooltipIcon
                      key={`${id}-legend-${i}`}
                      iconSize={'16px'}
                      gap={'4px'}
                      label={
                        <Typography key={`${id}-legend-${i}`} variant={'body2'}>
                          {subHeader}
                        </Typography>
                      }
                      tooltipText={subHeaderTooltip}
                    />
                  );
                } else {
                  return (
                    <Typography key={`${id}-legend-${i}`} variant={'body2'}>
                      {subHeader}
                    </Typography>
                  );
                }
              }
              if (legendTooltip && !disableLegend) {
                return (
                  <TextWithTooltipIcon
                    key={`${id}-legend-${i}`}
                    iconSize={'16px'}
                    gap={'4px'}
                    label={
                      <LegendValue
                        handleHover={(highlight) => {
                          handleLegendHover(index, highlight);
                        }}
                        inactive={legendActive.includes(index)}
                        onClick={() => handleLegendClick(index)}
                        value={label}
                        color={type === 'bar' ? backgroundColor : borderColor}
                      />
                    }
                    tooltipText={legendTooltip}
                  />
                );
              }
              return !disableLegend ? (
                <LegendValue
                  handleHover={(highlight) => {
                    handleLegendHover(index, highlight);
                  }}
                  inactive={legendActive.includes(index)}
                  onClick={() => handleLegendClick(index)}
                  key={`${id}-legend-${i}`}
                  value={label}
                  color={type === 'bar' ? backgroundColor : borderColor}
                />
              ) : null;
            }
          )}
      </Stack>
    );
  };

  const legendGroups = useMemo(() => {
    let groupList = [];
    data.datasets.forEach(({ group }) => {
      if (!!group && !groupList.includes(group)) {
        groupList.push(group);
      }
    });
    return groupList;
  }, [data]);

  if (legendGroups.length) {
    return (
      <Stack spacing={1}>
        {legendGroups.map((groupName, i) => {
          let currSet = [...data.datasets].filter(({ group }) => group === groupName);
          return renderLegend(currSet, groupName, `legend-${i}`);
        })}
      </Stack>
    );
  }
  return renderLegend(data.datasets);
}

CanvasLegends.propTypes = {
  chartRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  id: PropTypes.string,
  renderGroups: PropTypes.bool,
  data: PropTypes.object
};

export default CanvasLegends;
