import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { useTheme } from '@hooks';

const getDonutChartOptions = (categories, theme) => {
  const {
    palette: {
      mode,
      charts: { background, fourColorsPie }
    }
  } = theme;
  return {
    chart: {
      animations: {
        enabled: false
      },
      id: '-top-three-drilling-activities-chart',
      background: background,
      type: 'donut'
    },
    stroke: {
      colors: fourColorsPie
    },
    labels: categories,
    dataLabels: {
      style: {
        fontSize: '10px',
        fontFamily: ['Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
        fontWeight: 100,
        colors: ['#000', '#000', '#000', '#fff']
      },
      dropShadow: {
        enabled: false
      },
      formatter: function (val) {
        return `${val}%`;
      }
    },
    plotOptions: {
      donut: { labels: { show: false } }
    },
    colors: fourColorsPie,
    legend: { show: false },
    theme: {
      mode: mode
    },
    noData: {
      text: 'There is no data to display'
    }
  };
};

function TopThreeDrillingActivitiesChart({ loading, series, categories }) {
  const { theme } = useTheme();

  const options = useMemo(() => {
    return getDonutChartOptions(categories, theme);
  }, [theme, categories]);

  return (
    <ApexChart
      newLoadingAnimation
      loading={loading || !options}
      height={200}
      options={options || {}}
      type={'donut'}
      data={series}
    />
  );
}

TopThreeDrillingActivitiesChart.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  series: PropTypes.arrayOf(PropTypes.number)
};

TopThreeDrillingActivitiesChart.defaultProps = {
  categories: [],
  loading: false,
  series: []
};

export default TopThreeDrillingActivitiesChart;
