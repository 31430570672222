import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CloseIcon,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography
} from '@esgian/esgianui';
import moment from 'moment-timezone';
import { fetchDayReportData, fetchHourReportData } from '@api/DdrReport';
import LogPeriodDisplay from '@components/Drawers/LogDrawer/LogPeriodDisplay';
import { useSelector } from 'react-redux';
import { APP_PAGES, DATE_TIMEZONE_FORMAT, TIMESTAMP, UTC_TIMESTAMP } from '@constants';
import { useQuery } from '@tanstack/react-query';
import { useTimezone } from '@hooks/useTimezone';

function LogDrawer({ open, handleClose, logInfo, page }) {
  const [logStart, setLogStart] = useState(null);
  const [logEnd, setLogEnd] = useState(null);
  const { selectedTimeZone } = useTimezone();

  const selectedRig = useSelector(({ filters }) => {
    if (page === APP_PAGES.BASELINE_COMP) {
      return filters.baselineComparison.selectedRig;
    } else {
      return filters.mainPage.selectedRig;
    }
  });
  const handleCloseEvent = useCallback(() => {
    handleClose();
  }, []);

  const logDataQuery = useQuery({
    queryKey: [
      'logInfo',
      {
        selectedRig: selectedRig,
        logStart: logStart?.clone().format(DATE_TIMEZONE_FORMAT),
        logEnd: logEnd?.clone().format(DATE_TIMEZONE_FORMAT),
        unit: logInfo?.unit
      }
    ],
    enabled: !!selectedRig && !!logInfo && !!logStart,
    placeholderData: [],
    queryFn: ({ signal }) => {
      if (['hour', 'custom'].includes(logInfo.unit)) {
        let dateStart = logStart.clone().format(TIMESTAMP);
        let dateEnd = logEnd.clone().format(TIMESTAMP);
        return fetchHourReportData(signal, {
          selectedRig: selectedRig,
          startDate: moment.tz(dateStart, selectedTimeZone).format(DATE_TIMEZONE_FORMAT),
          endDate: moment.tz(dateEnd, selectedTimeZone).format(DATE_TIMEZONE_FORMAT)
        })
          .then((result) => {
            const { truncatedevents } = result;
            return truncatedevents.sort((a, b) => {
              return (
                moment.parseZone(a.startdate).valueOf() - moment.parseZone(b.startdate).valueOf()
              );
            });
          })
          .catch(() => {
            return [];
          });
      }
      return fetchDayReportData(signal, { selectedRig: selectedRig, reportDate: logStart })
        .then((result) => {
          const { documents } = result;
          if (documents.length) {
            let dayDocument = documents.find(({ reportDate }) => {
              return moment.utc(reportDate).isSame(moment(logStart.format(UTC_TIMESTAMP)), 'day');
            });
            return [{ ...dayDocument, company: dayDocument?.wellData?.company ?? null }];
          }
        })
        .catch(() => {
          return [];
        });
    }
  });

  useEffect(() => {
    if (!logInfo) return;
    const { startDate, endDate } = logInfo;
    if (logInfo.unit === 'custom') {
      setLogStart(moment.parseZone(startDate));
      setLogEnd(moment.parseZone(endDate));
    } else {
      let start = moment.parseZone(startDate);
      setLogStart(start);
      setLogEnd(start.clone().add(1, logInfo.unit));
    }
  }, [logInfo]);

  const { wellName, company, daySummary, wellname } = logDataQuery.data?.[0] || {};

  const { rigName } = selectedRig || {};
  const loading = logDataQuery.isFetching || logDataQuery.isStale;
  return (
    <Drawer
      PaperProps={{
        sx: {
          background: ({ palette }) => palette.background.drawer
        }
      }}
      open={open}
      onClose={handleCloseEvent}
      anchor={'right'}>
      <Grid
        container
        spacing={2}
        sx={{
          width: '360px',
          maxHeight: '100%'
        }}>
        <Grid sx={{ height: '57px' }} item xs={12}>
          <Grid justifyContent={'space-between'} container sx={{}}>
            <Grid item alignSelf={'center'} sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
              <Typography variant={'h6'} color={'text.primary'}>
                Log
              </Typography>
            </Grid>
            <Grid item sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
              <IconButton onClick={handleCloseEvent}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={'row'} sx={{ pl: 2, pr: 2, pt: 3 }} justifyContent={'space-between'}>
            <Stack>
              <Typography variant={'body2'}>Rig name:</Typography>
              <Typography variant={'body2'}>Well ID:</Typography>
              <Typography variant={'body2'}>Operator:</Typography>
            </Stack>
            <Stack sx={{ width: loading ? '30%' : 'fit-content' }}>
              <Typography alignSelf={'end'} variant={'body2'}>
                {loading ? <Skeleton width={'6.5em'} /> : rigName || '-'}
              </Typography>
              <Typography alignSelf={'end'} variant={'body2'}>
                {loading ? <Skeleton width={'6.5em'} /> : wellName || wellname || '-'}
              </Typography>
              <Typography alignSelf={'end'} variant={'body2'}>
                {loading ? <Skeleton width={'6.5em'} /> : company || '-'}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <LogPeriodDisplay
          setLogEnd={setLogEnd}
          setLogStart={setLogStart}
          startDate={logStart}
          unit={logInfo?.unit}
          endDate={logEnd}
          loading={loading}
        />
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack
            spacing={2}
            sx={{
              pl: 2,
              pr: 2,
              pb: 4,
              overflow: 'auto',
              maxHeight: 'calc(100vh - 17.8em)',
              '::-webkit-scrollbar': {
                width: '8px'
              },

              /* Track */
              '::-webkit-scrollbar-track': {
                borderRadius: '10px',
                background: ({ palette }) => palette.neutral.neutral01
              },

              /* Handle */
              ' ::-webkit-scrollbar-thumb': {
                background: (theme) => theme.palette.primary.main,
                borderRadius: '10px'
              }
            }}>
            {loading &&
              [...Array(3)].map((_, i) => (
                <Stack key={`${i}-loading`}>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </Stack>
              ))}
            {!loading && logInfo?.unit === 'day' && (
              <>
                <Typography variant={'subtitle1'} bold>
                  24 Hrs. Summary:
                </Typography>
                <Typography variant={'body2'}>
                  {daySummary?.split('24 Hrs. Summary:')[1]}
                </Typography>
              </>
            )}
            {!loading && logInfo?.unit === 'hour' && !logDataQuery.data?.length && (
              <Typography variant={'subtitle1'} bold>
                No summary events.
              </Typography>
            )}
            {!loading &&
              ['hour', 'custom'].includes(logInfo?.unit) &&
              logDataQuery.data?.map(({ comment, startdate, enddate, codename }, i) => {
                let header = 'Programmed Event (P) ';
                if (codename === 'Rig Downtime') {
                  header = 'Unplanned Downtime (UT) ';
                }
                let val = comment.split(header)[1];
                return (
                  <Stack key={`log-sum-${i}`}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography variant={'subtitle1'} bold>
                        {header}
                      </Typography>
                      <Typography variant={'caption'}>
                        {`(${moment.parseZone(startdate).format('HH:mm')}-${moment
                          .parseZone(enddate)
                          .format('HH:mm')})`}
                      </Typography>
                    </Stack>
                    <Typography variant={'body2'}>{val}</Typography>
                  </Stack>
                );
              })}
          </Stack>
        </Grid>
      </Grid>
    </Drawer>
  );
}

LogDrawer.propTypes = {
  open: PropTypes.bool,
  logInfo: PropTypes.shape({
    startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    unit: PropTypes.oneOf(['hour', 'day', 'custom'])
  }),
  handleClose: PropTypes.func.isRequired,
  page: PropTypes.oneOf(Object.values(APP_PAGES))
};

LogDrawer.defaultProps = {
  open: false,
  logInfo: {},
  page: APP_PAGES.RIG_PAGE
};

export default LogDrawer;
