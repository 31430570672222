import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  Stack,
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  DateRangeMobi
} from '@esgian/esgianui';
import moment from 'moment';
import { DATE_FORMAT, TIME_PERIOD_TABS } from '@constants';
import { useTimezone } from '@hooks/useTimezone';
import PropTypes from 'prop-types';

const TimePeriod = ({
  handleClose,
  onSave,
  startDate,
  endDate,
  setSelectedTimePeriod,
  setTabValue,
  display
}) => {
  const [tempDates, setTempDates] = useState([null, null]);
  const { selectedTimeZone } = useTimezone();
  const resetTempDates = useCallback(() => {
    let dates = [];
    dates[0] = startDate ? moment(startDate, DATE_FORMAT) : null;
    dates[1] = endDate ? moment(endDate, DATE_FORMAT) : null;
    setTempDates(dates);
  }, [startDate, endDate]);

  useEffect(() => {
    resetTempDates();
  }, [startDate, endDate]);

  const maxDate = useMemo(() => moment(), []);

  const handleApply = () => {
    onSave({
      startDate: tempDates[0].format(DATE_FORMAT),
      endDate: tempDates[1].format(DATE_FORMAT)
    });
    handleClose();
  };

  const handleSaveClick = () => {
    setSelectedTimePeriod({
      name: `${tempDates[0] ? moment(tempDates[0]).format(DATE_FORMAT) : ''} - ${
        tempDates[1] ? moment(tempDates[1]).format(DATE_FORMAT) : ''
      }`,
      startDate: moment(tempDates[0]).tz(selectedTimeZone, true),
      endDate: moment(tempDates[1]).tz(selectedTimeZone, true)
    });
    setTabValue(TIME_PERIOD_TABS.SAVE_TIME);
  };

  return (
    <Box sx={{ display: display ? 'block' : 'none' }}>
      <>
        <Box sx={{ height: 380 }}>
          <DateRangeMobi
            controls={['calendar']}
            maxDate={maxDate}
            dateFormat={DATE_FORMAT}
            pages={2}
            onClose={() => {}}
            sx={{
              inputProps: {
                autocomplete: 'off',
                defaultValue: undefined,
                placeholder: 'Period',
                value:
                  tempDates[0] || tempDates[1]
                    ? `${tempDates[0] ? moment(tempDates[0]).format(DATE_FORMAT) : ''} - ${
                        tempDates[1] ? moment(tempDates[1]).format(DATE_FORMAT) : ''
                      }`
                    : null
              },
              renderCalendarHeader: () => (
                <Box sx={{ width: '100%' }}>
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <CalendarPrev />
                    <CalendarNav />
                    <CalendarNext />
                  </Stack>
                </Box>
              ),
              touchUi: true,
              display: 'inline',
              theme: 'windows',
              value: tempDates,
              onChange: ({ value }) => {
                setTempDates(value);
              }
            }}
          />
        </Box>
        <Box
          sx={{
            height: '52px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <Button onClick={handleSaveClick} variant="outlined">
            SAVE IT
          </Button>
          <Box>
            <Button
              onClick={handleClose}
              variant="text"
              sx={{ color: (theme) => theme.palette.secondary.contrastText, mr: 4 }}>
              CANCEL
            </Button>
            <Button onClick={handleApply} variant="contained" color="primary">
              APPLY
            </Button>
          </Box>
        </Box>
      </>
    </Box>
  );
};

TimePeriod.propTypes = {
  setTabValue: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  setSelectedTimePeriod: PropTypes.func.isRequired,
  display: PropTypes.bool.isRequired
};

export default TimePeriod;
