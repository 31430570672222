import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  getMainPageSlice,
  setMainPagePeriod
} from '../../store/features/filters/MainPageSlice/MainPageSlice';
import { Box, ChevronRightIcon, IconButton } from '@esgian/esgianui';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { useTimezone } from '../../hooks/useTimezone';
import { DATE_FORMAT } from '../../constants';
import { parsTimeZone } from '../../helpers';
import { useQuery } from '@tanstack/react-query';
import { fetchRigWells } from '../../api/DdrReport';
import TimeRangeSlider from './TimeRangeSlider';
import WellsButton from './WellsButton';
import BarChart from './BarChart';
import DateRangeDropdown from '../DateRangeDropdown';

export const DateSlider = () => {
  const componentRef = useRef(null);

  const dispatch = useDispatch();
  const {
    selectedRig,
    startDate: selectedStartDate,
    endDate: selectedEndDate
  } = useSelector(getMainPageSlice);

  const { selectedTimeZone } = useTimezone();
  const { selectedDateName } = useSelector(getMainPageSlice);
  const [currentSelectedDates, setCurrentSelectedDates] = useState<[string, string]>([
    selectedStartDate,
    selectedEndDate
  ]);

  const fullStartDate = useMemo(() => {
    if (selectedRig.lowerLimit) {
      return parsTimeZone(selectedRig.lowerLimit, selectedTimeZone).startOf('D');
    }
    return parsTimeZone(moment(), selectedTimeZone).subtract(12, 'months').startOf('D');
  }, [selectedRig]);

  const fullEndDate = useMemo(() => parsTimeZone(moment(), selectedTimeZone).endOf('D'), []);

  const isMoreThanOneYear = useMemo(
    () => fullEndDate.diff(fullStartDate, 'months') > 12,
    [fullStartDate, fullEndDate]
  );

  const initialDisplayedStart = useMemo(() => {
    return isMoreThanOneYear
      ? fullEndDate.clone().subtract(12, 'months').startOf('D')
      : fullStartDate.clone().startOf('D');
  }, [isMoreThanOneYear]);

  const initialDisplayedEnd = useMemo(() => fullEndDate.clone(), [fullEndDate]);

  const [currentDisplayedDates, setCurrentDisplayedDates] = useState([
    initialDisplayedStart,
    initialDisplayedEnd
  ]);

  const onSave = (period: { startDate: string; endDate: string }) => {
    dispatch(setMainPagePeriod(period));
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      const [newStartDate, newEndDate] = currentSelectedDates;
      const isStartDateChanged = !moment(newStartDate).isSame(moment(selectedStartDate), 'day');
      const isEndDateChanged = !moment(newEndDate).isSame(moment(selectedEndDate), 'day');
      if (isStartDateChanged || isEndDateChanged) {
        onSave({
          startDate: newStartDate,
          endDate: newEndDate
        });
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [currentSelectedDates]);

  useEffect(() => {
    setCurrentSelectedDates([selectedStartDate, selectedEndDate]);
  }, [selectedStartDate, selectedEndDate]);

  const handleMoveTimeline = (direction: string) => {
    const [currentStart, currentEnd] = currentDisplayedDates;
    const newStart =
      direction === 'left'
        ? currentStart.clone().subtract(30, 'days')
        : currentStart.clone().add(30, 'days');

    const newEnd =
      direction === 'left'
        ? currentEnd.clone().subtract(30, 'days')
        : currentEnd.clone().add(30, 'days');
    if (newStart.isBefore(fullStartDate)) {
      setCurrentDisplayedDates([
        fullStartDate,
        parsTimeZone(currentDisplayedDates[1], selectedTimeZone).endOf('D')
      ]);
    } else if (newEnd.isAfter(fullEndDate)) {
      setCurrentDisplayedDates([
        parsTimeZone(currentDisplayedDates[0], selectedTimeZone).startOf('D'),
        fullEndDate
      ]);
    } else {
      setCurrentDisplayedDates([
        parsTimeZone(newStart, selectedTimeZone).startOf('D'),
        parsTimeZone(newEnd, selectedTimeZone).endOf('D')
      ]);
    }
  };

  const wellsLookup = useQuery({
    queryKey: ['lookupWells', { selectedRig: selectedRig }],
    enabled: !!selectedRig,
    placeholderData: [],
    queryFn: ({ queryKey, signal }: any) => {
      const endDate = moment().format(DATE_FORMAT);
      return fetchRigWells(signal, { ...queryKey[1], endDate })
        .then((result: any) => {
          return result
            .filter(({ starttime, endtime }: { starttime: string; endtime: string }) => {
              return (
                moment(endtime).diff(moment(starttime), 'days') >= 3 &&
                moment(starttime).isSameOrAfter(fullStartDate) &&
                moment(endtime).isSameOrBefore(fullEndDate)
              );
            })
            .sort(
              (
                a: { starttime: string; endtime: string },
                b: { starttime: string; endtime: string }
              ) => {
                return (
                  moment.parseZone(b.starttime).valueOf() - moment.parseZone(a.starttime).valueOf()
                );
              }
            );
        })
        .catch(() => []);
    }
  });

  useEffect(() => {
    const preventZoom = (e: any) => {
      if (e.ctrlKey || e.metaKey || e.deltaY) {
        e.preventDefault();
      }
    };

    const element: any = componentRef.current;
    element.addEventListener('wheel', preventZoom, { passive: false });

    return () => {
      element.removeEventListener('wheel', preventZoom);
    };
  }, []);

  return (
    <Box sx={{ width: '100%', position: 'relative' }} id={'date-range-section'}>
      <DateRangeDropdown
        startDate={currentSelectedDates[0]}
        endDate={currentSelectedDates[1]}
        selectedDateName={selectedDateName || ''}
        onSave={(period) => dispatch(setMainPagePeriod(period))}
        inputVariant="noBorder"
        maxWidth={310}
        fullWidth={true}
        allowTimeZoneChange
      />
      <Box sx={{ display: 'flex', mt: '8px' }}>
        {isMoreThanOneYear ? (
          <IconButton
            size={'small'}
            onClick={() => handleMoveTimeline('left')}
            sx={{ padding: 0, marginRight: 1, rotate: '180deg', height: 56 }}
            disabled={parsTimeZone(currentDisplayedDates[0], selectedTimeZone).isSameOrBefore(
              fullStartDate
            )}>
            <ChevronRightIcon fontSize="small" color={'inherit'} />
          </IconButton>
        ) : null}
        <Box
          ref={componentRef}
          sx={{
            width: '100%',
            height: '72px',
            position: 'relative',
            touchAction: 'none',
            userSelect: 'none'
          }}
          id={'date-range-slider'}>
          <WellsButton
            wells={wellsLookup.data}
            start={currentDisplayedDates[0]}
            end={currentDisplayedDates[1]}
            selectedRange={currentSelectedDates}
            selectedTimeZone={selectedTimeZone}
            onSave={onSave}
          />
          <BarChart
            start={currentDisplayedDates[0]}
            end={currentDisplayedDates[1]}
            selectedDates={currentSelectedDates}
            earliestStart={fullStartDate}
            latestEnd={fullEndDate}
            setCurrentDisplayedDates={setCurrentDisplayedDates}
            selectedRig={selectedRig}
            selectedTimeZone={selectedTimeZone}
          />
          <TimeRangeSlider
            start={currentDisplayedDates[0]}
            end={currentDisplayedDates[1]}
            selectedRange={currentSelectedDates}
            onRangeChange={(range) => setCurrentSelectedDates(range)}
            selectedTimeZone={selectedTimeZone}
          />
        </Box>
        {isMoreThanOneYear ? (
          <IconButton
            size={'small'}
            onClick={() => handleMoveTimeline('right')}
            sx={{ padding: 0, marginLeft: 1, height: 56 }}
            disabled={moment(currentDisplayedDates[1]).isSameOrAfter(fullEndDate)}>
            <ChevronRightIcon fontSize="small" color={'inherit'} />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  );
};
